import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import webVideo from "../assets/star_desktop.mp4";
import mobileVideo from "../assets/star_mobile.mp4";
import logo from "../assets/logo.png";
import { Button, Modal, notification } from "antd";
const options = [
  { id: 1, name: "1 цаг 1000₮", hour: 1, price: 1000, profile: "1 Hour" },
  { id: 2, name: "3 цаг 2500₮", hour: 3, price: 2500, profile: "3 Hour" },
  { id: 3, name: "7 цаг 5000₮", hour: 7, price: 5000, profile: "7 Hour" },
  { id: 4, name: "12 цаг 8000₮", hour: 12, price: 8000, profile: "12 Hour" },
];

const bank = [
  "State bank 3.0",
  "Trade and Development bank",
  "Social Pay",
  "Xac bank",
  "Capitron bank",
  "Khan bank",
  "Monpay",
  "M bank",
  "Arig bank"
];
const User = () => {
  const [videoSource, setVideoSource] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const arrayData = location.state?.arrayData;
  const qrImage1 = location.state?.qrImage;
  const invoice_id = location.state?.invoiceId;
  const orderEmail = location.state?.email;
  const [email] = useState(orderEmail);
  const [qrImage] = useState(qrImage1);
  const [invoiceId] = useState(invoice_id);
  const [urls] = useState(arrayData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [response, setResponse] = useState("");
  const filteredItem = options.find((item) => item.id === 1);
  // console.log("urls: ", urls);
  const [bankList, setBankList] = useState([]);
  const [counter, setCounter] = React.useState(60);

  useEffect(() => {
    if (urls) {
      const filteredBanks = urls.filter((item) => bank.includes(item.name));
      const orderedBanks = filteredBanks.sort(
        (a, b) => bank.indexOf(a.name) - bank.indexOf(b.name)
      );
      setBankList(orderedBanks);
    }
  }, [urls]);

  useEffect(() => {

    setInterval(() => setCounter(counter - 1), 1000);
    const detectScreenWidth = () => {
      const isMobile = window.innerWidth <= 768;
      setVideoSource(isMobile ? mobileVideo : webVideo);
    };

    detectScreenWidth();
    window.addEventListener("resize", detectScreenWidth);

    return () => {
      window.removeEventListener("resize", detectScreenWidth);
    };
  }, []);

  function generateUsername() {
    const letters = "ABCDEFGHJKLMNOPQRSTUVWXYZ";
    const digits = "0123456789";

    let username = "";

    for (let i = 0; i < 2; i++) {
      username += letters.charAt(Math.floor(Math.random() * letters.length));
    }

    for (let i = 0; i < 3; i++) {
      username += digits.charAt(Math.floor(Math.random() * digits.length));
    }
    return username;
  }

  useEffect(() => {
    if (invoiceId) {
      const interval = setInterval(onCheck, 5000);
      return () => clearInterval(interval);
    }
  }, [invoiceId]);

  const onCheck = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/check`,
        { invoiceId }
      );

      if (res.data) {
        // const status =
        //   res.data.count !== 0 ? "Төлбөр төлөлт амжилттай" : "Төлөгдөөгүй";
        // setResponse(status);

        if (res.data.count !== 0) {
          clearInterval(onCheck);
          const generatedUsername = generateUsername();
          if (generatedUsername) {
            try {
              const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_API_URL}/api/user`,
                {
                  name: generatedUsername,
                  profile: filteredItem.profile,
                  email: email,
                  invoice_id: invoiceId,
                }
              );
              if (response.status === 200) {
                navigate("/info", { state: { data: generatedUsername } });
              }
            } catch (error) {
              console.error("Error creating user:", error);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error making POST request:", error);
    }
  };


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleAppRedirect = (url, fallbackUrl) => {
    const timeout = 2000; // Time to wait before showing an error
    let hasOpenedApp = false;

    // Set a timeout to show an error if the app is not opened
    const timer = setTimeout(() => {
      if (!hasOpenedApp) {
        showModal(); // Show modal if app did not open
      }
    }, timeout);

    // Attempt to open the app URL
    window.location.href = url;

    // Add a visibilitychange event listener to check if the app was successfully opened
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        hasOpenedApp = true;
        clearTimeout(timer);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
  };


  return (
    <div className="landing">
      <div className="overlay"></div>
      <video
        id="video"
        autoPlay
        muted
        loop
        playsInline
        src={videoSource}
      ></video>

      <div className="contentLanding">
        <img src={logo} alt="logowhite" className="landing-logo" />
        <div className="hr-sl"></div>
        <p className="landing-text">явдаг интернэт</p>
        <div className="outside">
          <div className="o-payment">Төлбөр төлөх</div>
          <div className="user-container">
            <div className="qr-container">
              <img
                src={`data:image/png;base64,${qrImage}`}
                alt="QR Code"
                style={{ width: "100%", maxWidth: "400px" }}
              />
            </div>-
            <div className="user-grid">
              {bankList.map((url, index) => (
                <div key={index} style={{ textAlign: "center" }}>
                  {/* <a href={url.link} target="_blank" rel="noopener noreferrer"> */}
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAppRedirect(url.link);
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={url.logo} alt={url.name} className="grid-logo" />
                  </a>
                  <p className="grid-desc">
                    {url.description.length > 16
                      ? `${url.description.slice(0, 16)}...`
                      : url.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Modal
          title="Анхааруулга"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleOk}
          footer={[
            <Button key="ok" type="primary" onClick={handleOk}>
              ОК
            </Button>,
          ]}
        >
          <p>Төхөөрөмжийн тохиргооноос хамаарч банкны мобайл аппликэшнийг нээх боломжгүй байгаа тул
            доорх холбоосыг веб хөтөч дээр дуудаж ашиглана уу.
          </p>
          <div style={{display:"flex", flexDirection:"row", alignContent:"center", justifyContent:"flex-end"}}>
            <span style={{ color: "black"}} > https://portal.starlinknomad.mn</span>
            <Button
            style={{marginLeft:10, border:"1px solid gray", color:"black"}}
              type="link"
              onClick={() => {
                navigator.clipboard.writeText("https://portal.starlinknomad.mn");
              }}
            >
              хуулах
            </Button>
          </div>
        </Modal>
        {/* <div className="check-payment">
          <Button className="check-button" key="check" onClick={onCheck}>
            Төлбөр шалгах
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default User;