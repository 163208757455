import { Routes, Route, BrowserRouter } from "react-router-dom";
import App from "./App";
import Home from "./Home";
import Order from "./components/Order";
import User from "./components/User";
import Info from "./components/Info";
import Status from "./components/Status";
// import Home from "./Home";

const { PUBLIC_URL } = process.env;

const AppRoutes = () => {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="/" element={<Home />} />
          <Route path="/order/:id" element={<Order />} />
          <Route path="/user" element={<User />} />
          <Route path="/info" element={<Info />} />
          <Route path="/status" element={<Status />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
