import React, { useState, useEffect } from "react";
import {  notification } from "antd";
import { useLocation } from "react-router-dom";
import copy from "../assets/icon/copy.png";
import webVideo from "../assets/star_desktop.mp4";
import mobileVideo from "../assets/star_mobile.mp4";
import logo from "../assets/logo.png";

const Info = () => {
  const [videoSource, setVideoSource] = useState(null);
  const location = useLocation();
  const arrayData = location.state?.data;
  const [username] = useState(arrayData);

  useEffect(() => {
    const detectScreenWidth = () => {
      const isMobile = window.innerWidth <= 768;
      setVideoSource(isMobile ? mobileVideo : webVideo);
    };

    detectScreenWidth();
    window.addEventListener("resize", detectScreenWidth);

    return () => {
      window.removeEventListener("resize", detectScreenWidth);
    };
  }, []);

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    notification.success({
      message: "Амжилттай хууллаа.",
      duration: 3,
    });
  };

  const login = () => {
    window.open("http://wifi.starlinknomad.mn", "_blank");
  };
  return (
    <div className="landing">
      <div className="overlay"></div>
      <video
        id="video"
        autoPlay
        muted
        loop
        playsInline
        src={videoSource}
      ></video>

      <div className="contentLanding">
        <img src={logo} alt="logowhite" className="landing-logo" />
        <div className="hr-sl"></div>
        <p className="landing-text">явдаг интернэт</p>
        <div className="outside">
          <div className="o-title"> Нэвтрэх кодыг хадгалж авна уу!</div>
          <div style={{marginTop:20, width:300, fontSize:14, marginLeft:10, textAlign:"center"}}> *Кодыг хадгалж аваагүй тохиолдолд нэвтрэх боломжгүйг анхаарна уу!</div>
          <div style={{ width:300, fontSize:10, marginLeft:10, marginTop:40, textAlign:"center"}}> Copy+Paste хийх</div>
          <div style={{ width:300, fontSize:10, marginLeft:10, marginTop:10, textAlign:"center"}}> Screenshot хийх</div>
          <div style={{ width:300, fontSize:10, marginLeft:10, marginTop:10, textAlign:"center", marginBottom:80}}> Илгээсэн и-мэйл дээрээс харах</div>
          <div className="o-container">
            <div className="login-info">
              <div className="code">
                <strong onClick={() => copyText(username)}>{username}</strong>
              </div>
              <div className="copy blinking">
                <img
                  src={copy}
                  style={{
                    width: 80,
                    height: "100%",
                    marginLeft: 20,
                  }}
                  onClick={() => copyText(username)}
                  alt="ty"
                ></img>
                <span style={{marginLeft:27, fontSize:12}}>copy</span>
              </div>
            </div>
          </div>
          <div className="login-container" style={{marginTop:-20}}>
            <button className="login-button" onClick={login}>
              Connect
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
