import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import { useLocation } from "react-router-dom";
import axios from "axios";
import webVideo from "../assets/star_desktop.mp4";
import mobileVideo from "../assets/star_mobile.mp4";
import logo from "../assets/logo.png";

const Status = () => {
  const [videoSource, setVideoSource] = useState(null);
  const [sessionData, setSessionData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [username, setUsername] = useState(null);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const usernameParam = queryParams.get("id");
    setUsername(usernameParam);
  }, [location.search]);

  useEffect(() => {
    const detectScreenWidth = () => {
      const isMobile = window.innerWidth <= 768;
      setVideoSource(isMobile ? mobileVideo : webVideo);
    };

    detectScreenWidth();
    window.addEventListener("resize", detectScreenWidth);

    return () => {
      window.removeEventListener("resize", detectScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (username) {
      const fetchSessionData = async () => {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_API_URL}/api/session`,
            { name: username }
          );
          console.log(res.data);
          setSessionData(res.data.session);

          setUserData(res.data.userInfo[0]);
        } catch (error) {
          console.error("Error making POST request:", error);
        }
      };

      fetchSessionData();
    }
  }, [username]);

  const logout = () => {
    window.open("https://wifi.starlinknomad.mn/logout", "_blank");
  };
  const getPackage = () => {
    window.open("https://portal.starlinknomad.mn", "_blank");
  };

  return (
    <div className="landing">
      <div className="overlay"></div>
      <video
        id="video"
        autoPlay
        muted
        loop
        playsInline
        src={videoSource}
      ></video>

      <div className="contentLanding">
        <img src={logo} alt="logowhite" className="landing-logo" />
        <div className="hr-sl"></div>
        <p className="landing-text">явдаг интернэт</p>
        <div className="outside">
          <div className="card-title">Холболтын мэдээлэл</div>
          {sessionData.length > 0 && (
            <div className="status-container">
              <div className="status-a">
                <span>Код</span>
                {/* <span>Багц</span> */}
                <span>IP хаяг</span>
                <span>MAC хаяг</span>
                <span style={{ fontSize: 12 }}>Холболт үүссэн цаг</span>
                {/* <span>Time left</span> */}
                <span>Дата Up/Down</span>
              </div>
              <div className="status-b">
                <span>{sessionData[0]?.user}</span>
                {/* <span>{userData?.profile}</span> */}
                {/* <span>
                  {userData?.state === "running-active"
                    ? "идэвхтэй"
                    : "идэвхгүй"}
                </span> */}
                <span>{sessionData[0]?.["user-address"]}</span>
                <span>{sessionData[0]?.["calling-station-id"]}</span>
                <span onClick={showModal}>{sessionData[0]?.uptime}</span>
                <span>
                  {sessionData[0]?.upload} / {sessionData[0]?.download}
                </span>
              </div>
            </div>
          )}
          <div className="session-button">
            <Button className="check-button" onClick={logout}>
              LOGOUT
            </Button>
            <Button
              className="check-button"
              onClick={getPackage}
              style={{ marginLeft: 10 }}
            >
              БАГЦ АВАХ
            </Button>
          </div>
        </div>
      </div>
      <Modal
        title="Basic Modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <>
          <div
            style={{
              border: "1px solid black",
              display: "flex",
              justifyContent: "space-between",
              fontSize: 10,
            }}
          >
            <span>Start: </span>
            <span style={{ marginLeft: 20 }}>End: </span>
            <span style={{ marginRight: 10 }}>Up time:</span>
          </div>
          {sessionData.map((item) => (
            <div
              style={{
                border: "1px solid black",
                display: "flex",
                justifyContent: "space-between",
                fontSize: 10,
              }}
            >
              <span>{item.started}</span>
              <span>{item.ended}</span>
              <span style={{ marginRight: 10 }}>{item.uptime}</span>
            </div>
          ))}
        </>
      </Modal>
    </div>
  );
};

export default Status;
