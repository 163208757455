import React, { useEffect, useState } from "react";
import webVideo from "./assets/star_desktop.mp4";
import mobileVideo from "./assets/star_mobile.mp4";
import logo from "./assets/logo.png";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

const Landing = () => {
  const navigate = useNavigate();
  const [videoSource, setVideoSource] = useState(null);

  useEffect(() => {
    const detectScreenWidth = () => {
      const isMobile = window.innerWidth <= 768;
      setVideoSource(isMobile ? mobileVideo : webVideo);
    };

    detectScreenWidth();
    window.addEventListener("resize", detectScreenWidth);

    return () => {
      window.removeEventListener("resize", detectScreenWidth);
    };
  }, []);

  const options = [
    { id: 1, name: "1 цаг 1000₮", hour: 1, price: 1000, profile: "1 Hour" },
    { id: 2, name: "3 цаг 2500₮", hour: 3, price: 2500, profile: "3 Hour" },
    { id: 3, name: "7 цаг 5000₮", hour: 7, price: 5000, profile: "7 Hour" },
    { id: 4, name: "12 цаг 8000₮", hour: 12, price: 8000, profile: "12 Hour" },
  ];

  const handleClick = (item) => {
    navigate(`/order/${item}`);
  };

  return (
    <div className="landing">
      <div className="overlay"></div>
      <video
        id="video"
        autoPlay
        muted
        loop
        playsInline
        src={videoSource}
      ></video>

      <div className="contentLanding">
        <img src={logo} alt="logowhite" className="landing-logo" />
        <div className="hr-sl"></div>
        <p className="landing-text">явдаг интернэт</p>
        <div className="outside">
          <div className="card-title">БАГЦУУД</div>
          <div className="background-container">
            <div className="card-container">
              {options.map((item, index) => (
                <div
                  className="card"
                  key={item.id}
                  onClick={() => handleClick(item.id)}
                >
                  <span className="card-time"> {item.hour} ЦАГ</span>
                  <span className="card-price">
                    {item.price} ₮
                  </span>
                  <span className="card-text">ДАТА ХЯЗГААРГҮЙ </span>
                </div>
              ))}
            </div>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"center", marginTop:15}}>
            <Button className="back-button">БУЦАХ</Button>
            <Button className="back-button">ЗААВАР</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
